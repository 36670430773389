import { Row, Col, Card, Table, Input, Typography, Button } from "antd";

import { axiosInstance } from "../utils/axiosinstance";
import { useState, useEffect } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const Loans = () => {
  const { Title } = Typography;

  const [searchText, setSearchText] = useState("");

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const history = useHistory();
  const handleViewRecord = (loan) => {
    history.push("/loandetails", { loan });
  };

  const [clients, setClients] = useState([]);

  const getClients = async () => {
    try {
      //setLoading(true);
      const response = await axiosInstance.get(
        `https://easybills.pickmesms.com/items/loans?fields=*,client.*,transactions.*`
      );
      //setLoading(false);

      setClients(response.data.data);
    } catch (error) {
      //setLoading(false);
      //message.error(error.message);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const columns = [
    {
      title: "LOAN AMOUNT",
      dataIndex: "loan_amount",
      key: "loan_amount",
      width: "32%",
      render: (loan_amount, record) => (
        <div className="avatar-info">
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckOutlined
              style={{ color: "green", fontSize: "20px", marginRight: "20px" }}
            />
            <div>
              <Title level={5}>
                K{parseFloat(loan_amount).toLocaleString()}
              </Title>
              <p>{record.client.first_name + " " + record.client.last_name}</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "REPAYABLE",
      dataIndex: "repayable",
      key: "repayable",
      render: (repayable, record) => (
        <>
          <div className="author-info">
            <Title level={5}>K{parseFloat(repayable).toLocaleString()}</Title>
          </div>
        </>
      ),
    },

    {
      title: "Installment",
      key: "istallment",
      dataIndex: "istallment",
      render: (istallment, record) => (
        <>
          <div className="author-info">
            <Title level={5}>K{parseFloat(istallment).toLocaleString()}</Title>
          </div>
        </>
      ),
    },
    {
      title: "Rate",
      key: "rate",
      dataIndex: "rate",
      render: (rate, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{rate}%</Title>
          </div>
        </>
      ),
    },
    {
      title: "Tenor",
      key: "tenor",
      dataIndex: "tenor",
      render: (tenor, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{tenor} months</Title>
          </div>
        </>
      ),
    },
    {
      title: "VIEW",
      key: "transactions",
      render: (transactions, record) => (
        <Button type="link" onClick={() => handleViewRecord(transactions)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Loans"
              extra={
                <>
                  <Input.Search
                    placeholder="Search"
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchText}
                  />
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={clients}
                  className="ant-border-space"
                  pagination={{
                    pageSize: 5,
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Loans;
