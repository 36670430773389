import React, { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

export const userContext = createContext();

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({
    access_token: "",
    expires: "",
    refresh_token: "",
  });

  const [person, setPerson] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const [cgrate, setCgrate] = useState({
    balance: "",
  });

  const history = useHistory();

  const updateUser = (values) => {
    axios
      .post("https://easybills.pickmesms.com/auth/login", {
        email: values.email,
        password: values.password,
      })
      .then((response) => {
        const { access_token, expires, refresh_token } = response.data.data;
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("expires", expires);

        // Set the user state
        setUser({
          access_token,
          expires,
          refresh_token,
        });

        // Fetch the actual user data using the bearer token (access_token)
        axios
          .get("https://easybills.pickmesms.com/users/me", {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          })
          .then((userResponse) => {
            const { role, first_name, last_name, email } =
              userResponse.data.data;

            setPerson({
              first_name,
              last_name,
              email,
            });
            localStorage.setItem("role", role);
            if (role.trim() === "b9aaf76d-81ed-42ad-8613-5ce095285ca0") {
              history.push("/profile", { record: userResponse.data.data });
            } else {
              history.push("/dashboard");
            }
          })
          .catch((userError) => {
            console.log("Error fetching user data:", userError);
          });

        // axios
        //   .post("https://cgrate.pickmesms.com/getAccountBalance")
        //   .then((response) => {
        //     setCgrate({
        //       balance: response.data.balance,
        //     });
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
        axios
        .get("https://easybills.pickmesms.com/items/amazambia_balance/1")
        .then((response) => {
         
          setCgrate({
            balance: response.data.data.balance,
          });
        })
        .catch((error) => {
          console.log(error);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // Perform any initial user loading or check here (if required)
    // For example, you might check if there's a token in localStorage and automatically log in the user if present.
  }, []);

  return (
    <React.Fragment>
      <userContext.Provider value={{ user, person, cgrate, updateUser }}>
        {children}
      </userContext.Provider>
    </React.Fragment>
  );
};

export default UserContextProvider;
