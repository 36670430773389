import Home from "../pages/home";
import Clients from "../pages/clients";
import Rtl from "../pages/Rtl";
import Profile from "../pages/profile";
import Loans from "../pages/loans";
import Main from "./layout/Main";
import { Route } from "react-router-dom";
import Loandetails from "../pages/loandetails";
import Apply from "../pages/apply";
import Sms from "../pages/sms";
import Cashout from "../pages/cashout";

const Master = () => {
  return (
    <Main>
      <Route exact path="/dashboard" component={Home} />
      <Route exact path="/clients" component={Clients} />
      <Route exact path="/loans" component={Loans} />
      <Route exact path="/transactions" component={Rtl} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/reports" component={Profile} />
      <Route exact path="/bulksms" component={Profile} />
      <Route exact path="/loandetails" component={Loandetails} />
      <Route exact path="/apply" component={Apply} />
      <Route exact path="/cashout" component={Cashout} />
      <Route exact path="/sms" component={Sms} />
    </Main>
  );
};

export default Master;
