import {
  Row,
  Col,
  Card,
  Descriptions,
  Timeline,
  Typography,
  Form,
  Input,
  Button,
  Avatar,
  List,
} from "antd";
import axios from "axios";

const Sms = () => {
  const { Title, Text } = Typography;

  const onFinish = async (values) => {
    try {
      // Make a POST request using Axios
      await axios.post("https://cgrate.pickmesms.com/sendsms", values);

      // You can perform further actions with the API response here
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error cases
    }
  };

  return (
    <div className="layout-content">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Card>
            <Title level={5}>Single SMS</Title>
            <Form name="myForm" onFinish={onFinish}>
              <Form.Item
                className="username"
                label="Phone Number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter phone number",
                  },
                ]}
              >
                <Input type="number" placeholder="Phone" />
              </Form.Item>
              <Form.Item
                className="username"
                label="Message"
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter Message",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Message" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card>
            <Title level={5}>Bulk SMS</Title>
            <Form name="myForm" onFinish={onFinish}>
              <Form.Item
                className="username"
                label="Message"
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter Message",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Message" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Sms;
