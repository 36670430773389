import { Form, Input, InputNumber, Select, Button, Card, Checkbox, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import { axiosInstance } from "../utils/axiosinstance";
import { useContext, useState, useEffect } from "react";
import { userContext } from "../context/UserContext";
import axios from 'axios';


const Cashout = () => {

const { cgrate } = useContext(userContext);
const { Option } = Select;

const onFinish = async (values) => {
   
    values.amount = parseInt(values.amount) + 5;
    try {
      const response = await axios.post("https://amazambia.app-express.net/withdraw.php", values);
    
      
      if (response.data.responseCode === "0") {
        history.push("/sign-in");
      } else {
        alert("Withdrawal Failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const history = useHistory();

  const handleBack = () => {
    history.goBack(); // Function to go back to the previous page
  };

  return (
    <div className="layout-content">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Card title="Cashout Form">
            <Form name="applicationForm" onFinish={onFinish}>
              <Form.Item
                className="username"
                label=  {"Amount Between K1 and K" + (cgrate.balance - 5)} 
                name="amount"
                rules={[
                    { required: true, message: "Please enter the amount needed" },
                    { type: 'number', min: 1, max: cgrate.balance - 5, message: "Amount must be between K1 and K" + (cgrate.balance - 5) }
                  ]}
              >
                 <InputNumber min={1} max={cgrate.balance - 5} />
              </Form.Item>
              <Form.Item
                name="issuer"
                label="Select Issuer"
                rules={[
                    { required: true, message: "Please select an issuer" },
                ]}
                >
                <Select placeholder="Select an issuer">
                    <Option value="Airtel">Airtel</Option>
                    <Option value="MTN">MTN</Option>
                    <Option value="Zamtel">Zamtel</Option>
                </Select>
                </Form.Item>
                <Form.Item
                    className="username"
                    label="Recepient Phone starting with (0)"
                    name="phone"
                    rules={[
                        {
                        required: true,
                        message: "Please enter the recepient phone number",
                        },
                    ]}
                    >
                    <Input type="number"  />
                    </Form.Item>
       
              <Form.Item
                className="username"
                name="agreeTerms"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: "Please agree to the terms and conditions",
                  },
                ]}
              >
                <Checkbox>I agree to the terms and conditions</Checkbox>
              </Form.Item>
              <Form.Item>
                <div className="button-container">
                  <div></div>{" "}
                  {/* Back button */}
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card title="Terms and Conditions">
            <p>
              <strong>Terms and Conditions</strong>
            </p>
            <p>
              <em>Effective Date:</em> 01-09-2023
            </p>
            <p>
            These terms and conditions apply to all withdrawal transactions made through this platform.
            </p>
            <p>
              <strong>1. Withdrawal Charge:</strong> A flat fee of K5 will be charged on all withdrawals, regardless of the amount withdrawn.
            </p>
            <p>
              <strong>2. Confirmation:</strong> By initiating a withdrawal, you acknowledge and agree to incur the K5 withdrawal charge, which will be automatically deducted from the remaining balance.
            </p>
            <p>
              <strong>3. Acceptance:</strong> Proceeding with a withdrawal transaction signifies your acceptance of these terms and conditions, including the K5 withdrawal charge.
            </p>
          
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Cashout;
