import { Form, Input, Button, Card, Checkbox, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import { axiosInstance } from "../utils/axiosinstance";

const Apply = () => {
  const onFinish = async (values) => {
    try {
      // Make a POST request using Axios
      await axiosInstance.post(
        "https://easybills.pickmesms.com/items/applications",
        values
      );

      // You can perform further actions with the API response here
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error cases
    }
  };

  const history = useHistory();

  const handleBack = () => {
    history.goBack(); // Function to go back to the previous page
  };

  return (
    <div className="layout-content">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Card title="Application Form">
            <Form name="applicationForm" onFinish={onFinish}>
              <Form.Item
                className="username"
                label="Amount Needed"
                name="amount"
                rules={[
                  { required: true, message: "Please enter the amount needed" },
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                className="username"
                label="Number of Months"
                name="tenor"
                rules={[
                  {
                    required: true,
                    message: "Please enter the number of months",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                className="username"
                label="Active Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter the active phone number",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                className="username"
                label="Guarantor Name"
                name="guarantor_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the guarantor name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="username"
                label="Guarantor Phone"
                name="guarantor_phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter the guarantor phone number",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                className="username"
                name="agreeTerms"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: "Please agree to the terms and conditions",
                  },
                ]}
              >
                <Checkbox>I agree to the terms and conditions</Checkbox>
              </Form.Item>
              <Form.Item>
                <div className="button-container">
                  <Button onClick={handleBack}>Back to Profile</Button>{" "}
                  {/* Back button */}
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card title="Terms and Conditions">
            <p>
              <strong>GPN Loans Terms and Conditions</strong>
            </p>
            <p>
              <em>Effective Date:</em> 11-08-2023
            </p>
            <p>
              These Terms and Conditions ("Terms") govern your use of loans
              provided by Easy Bills ("Company"). By applying for and accepting
              a loan from Easy Bills, you agree to be bound by these Terms.
              Please read them carefully.
            </p>
            <p>
              <strong>1. Loan Agreement:</strong> By accepting the loan, you
              enter into a binding agreement with Easy Bills for the specified
              loan amount, subject to the terms outlined below.
            </p>
            <p>
              <strong>2. Loan Repayment:</strong> Loans can be repaid through
              two methods:
            </p>
            <ul>
        
              <li>
                <strong>Client Payment Deduction:</strong> Alternatively, loan
                repayments can be deducted from payments received from your
                clients. You agree to promptly provide accurate information
                regarding client payments for deduction purposes.
              </li>
            </ul>
            <p>
              <strong>3. Loan Amount and Interest:</strong> The loan amount and
              applicable interest rates are determined based on your
              application, creditworthiness, and other factors considered by{" "}
              <strong>Easy Bills</strong>.
            </p>
            <p>
              <strong>4. Repayment Schedule:</strong> You will be provided with
              a repayment schedule outlining installment amounts, due dates, and
              payment methods. It is your responsibility to ensure timely
              repayments to avoid any penalties.
            </p>
            <p>
              <strong>5. Penalties and Fees:</strong> Late repayments may result
              in penalties and additional fees. These will be outlined in your
              loan agreement.
            </p>
            <p>
              <strong>6. Prepayment and Early Settlement:</strong> You have the
              option to make prepayments or settle your loan early. Any
              applicable charges for such actions will be specified in your loan
              agreement.
            </p>
            <p>
              <strong>7. Default and Collection:</strong> If you fail to make
              repayments in accordance with the agreed schedule,{" "}
              <strong>Easy Bills</strong> reserves the right to take appropriate
              actions, including legal remedies, to recover the outstanding loan
              amount.
            </p>
            <p>
              <strong>8. Personal Data and Privacy:</strong> By accepting these
              Terms, you consent to the collection, processing, and storage of
              your personal data for loan processing and servicing purposes.{" "}
              <strong>Easy Bills</strong> will handle your data in accordance
              with its privacy policy.
            </p>
            <p>
              <strong>9. Changes to Terms:</strong> <strong>Easy Bills</strong>{" "}
              reserves the right to amend these Terms at any time. Any changes
              will be communicated to you through appropriate channels.
            </p>
            <p>
              <strong>10. Governing Law:</strong> These Terms shall be governed
              by and construed in accordance with the laws of Zambia, without
              regard to its conflict of law principles.
            </p>
            <p>
              By accepting a loan from <strong>Easy Bills</strong>, you
              acknowledge that you have read, understood, and agreed to these
              Terms and Conditions.
            </p>
            <p>
              If you have any questions or concerns, please contact{" "}
              <strong>Easy Bills</strong>' customer support at 0979825070
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Apply;
