import {
  Row,
  Col,
  Card,
  Descriptions,
  Button,
  Table,
  Empty,
  Typography,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { axiosInstance } from "../utils/axiosinstance";
import { useState, useEffect } from "react";

const Overview = ({ client }) => {
  const { Title } = Typography;

  const [alerts, setAlerts] = useState([]);

  const getAlerts = async () => {
    try {
      //setLoading(true);
      const response = await axiosInstance.get(
        `https://easybills.pickmesms.com/items/messages?filter[phone][_eq]=${client.phone}`
      );
      //setLoading(false);

      setAlerts(response.data.data);
    } catch (error) {
      //setLoading(false);
      //message.error(error.message);
    }
  };

  useEffect(() => {
    getAlerts();
  });

  const humanize = (mydate) => {
    const date = new Date(mydate);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const formattedDate = date.toLocaleString("en-US", options);

    return formattedDate;
  };

  const columns = [
    {
      title: "DATE",
      dataIndex: "date_created",
      key: "date_created",
      render: (date, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{humanize(date)}</Title>
          </div>
        </>
      ),
    },
    {
      title: "MESSAGE",
      dataIndex: "message",
      key: "message",
      render: (message, record) => (
        <>
          <div className="author-info">
            <p>{message}</p>
          </div>
        </>
      ),
    },
  ];

  return (
    <Row gutter={[24, 0]}>
      <Col span={24} md={8} className="mb-24">
        <Card
          bordered={false}
          title={<h6 className="font-semibold m-0">Profile Information</h6>}
          className="header-solid h-full card-profile-information"
          extra={
            <Button type="link">
              <EditOutlined />
            </Button>
          }
          bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
        >
          <hr className="my-10" />
          <Descriptions>
            <Descriptions.Item label="Mobile" span={3}>
              {client.phone}
            </Descriptions.Item>
            <Descriptions.Item label="Email" span={3}>
              {client.email}
            </Descriptions.Item>
            <Descriptions.Item label="Employer" span={3}>
              {client.employer}
            </Descriptions.Item>
            <Descriptions.Item label="ID" span={3}>
              {client.national_id}
            </Descriptions.Item>
            <Descriptions.Item label="Active Loans" span={3}>
              {client.loans.length}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
      <Col span={24} md={16} className="mb-24 ">
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Inbox"
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={alerts}
                    className="ant-border-space"
                    pagination={{
                      pageSize: 5,
                    }}
                    locale={{
                      emptyText: <Empty description="No Messages" />, // Specify the custom message here
                    }}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default Overview;
