import { Row } from "antd";
const Documents = () => {
  return (
    <Row gutter={[24, 0]}>
      <h1>Documents</h1>
    </Row>
  );
};

export default Documents;
