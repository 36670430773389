import {
  Row,
  Col,
  Card,
  Descriptions,
  Timeline,
  Typography,
  Form,
  Input,
  Button,
  Avatar,
  List,
} from "antd";

import { useLocation } from "react-router-dom";
import Paragraph from "antd/lib/typography/Paragraph";
import axios from "axios";

const Loandetails = () => {
  const location = useLocation();
  const loan = location.state?.loan;
  const { transactions } = loan;
  const { Title, Text } = Typography;

  const onFinish = async (values) => {
    try {
      // Make a POST request using Axios
      await axios.post("https://cgrate.pickmesms.com/makepayment", values);

      // You can perform further actions with the API response here
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error cases
    }
  };

  const information = [
    // {
    //   title: "Oliver Liam",
    //   description: "Viking Burrito",
    //   address: "oliver@burrito.com",
    //   vat: "FRB1235476",
    // },
    // {
    //   title: "Lucas Harper",
    //   description: "Stone Tech Zone",
    //   address: "lucas@syone-tech.com",
    //   vat: "FRB1235476",
    // },
    // {
    //   title: "Oliver Liam",
    //   description: "ethan@fiber.com",
    //   vat: "NumberFRB1235476",
    // },
  ];

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col span={24} md={8} className="mb-24">
            <Card
              bordered={false}
              title={<h6 className="font-semibold m-0">Loan Summary</h6>}
              className="header-solid h-full card-profile-information"
              bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            >
              <hr className="my-10" />
              <Descriptions>
                <Descriptions.Item label="Loan Amount" span={3}>
                  K{parseFloat(loan.loan_amount).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item label="Approved On" span={3}>
                  {loan.date_approved}
                </Descriptions.Item>
                <Descriptions.Item label="Insurance" span={6}>
                  K{parseFloat(loan.insurance).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item label="Processing Fee" span={6}>
                  K{parseFloat(loan.processing_fee).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item label="Repayable" span={3}>
                  K{parseFloat(loan.repayable).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item label="Rate" span={3}>
                  {loan.rate}%
                </Descriptions.Item>
                <Descriptions.Item label="Installments" span={3}>
                  K{parseFloat(loan.istallment).toLocaleString()}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col span={24} md={8} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="timeline-box">
                <Title level={5}>Loan History</Title>

                <Paragraph
                  className="lastweek"
                  style={{ marginBottom: 30 }}
                ></Paragraph>

                <Timeline className="timelinelist">
                  {transactions.map((t, index) => (
                    <Timeline.Item color={t.Color} key={index}>
                      <div>
                        <Title level={5}>{t.description}</Title>
                        <Text>{t.Date}</Text>
                      </div>
                      <div>
                        <Text>K{parseFloat(t.amount).toLocaleString()}</Text>
                      </div>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </div>
            </Card>
          </Col>
          <Col span={24} md={8} className="mb-24">
            <Card>
              <Title level={5}>Make Payment (Mobile Money)</Title>
              <Form name="myForm" onFinish={onFinish}>
                <Form.Item
                  className="username"
                  label="Amount"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the amount!",
                    },
                  ]}
                >
                  <Input type="number" placeholder="Amount" />
                </Form.Item>
                <Form.Item
                  className="username"
                  label="Phone"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone number!",
                    },
                  ]}
                >
                  <Input type="number" placeholder="Phone" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={24} md={24} className="mb-24">
            <Card
              className="header-solid h-full"
              bordered={false}
              title={[<h6 className="font-semibold m-0">Upcoming Payments</h6>]}
              bodyStyle={{ paddingTop: "0" }}
            >
              <Row gutter={[24, 24]}>
                {information.map((i, index) => (
                  <Col span={24} key={index}>
                    <Card className="card-billing-info" bordered="false">
                      <div className="col-info">
                        <Descriptions title="Oliver Liam">
                          <Descriptions.Item label="Company Name" span={3}>
                            Viking Burrito
                          </Descriptions.Item>

                          <Descriptions.Item label="Email Address" span={3}>
                            oliver@burrito.com
                          </Descriptions.Item>
                          <Descriptions.Item label="VAT Number" span={3}>
                            FRB1235476
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Loandetails;
