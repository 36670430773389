import { Row, Col, Card, Table, Input, Button, Avatar, Typography } from "antd";

import { useHistory } from "react-router-dom";
import { axiosInstance } from "../utils/axiosinstance";
import { useState, useEffect } from "react";
import { isTokenExpired } from "../utils/utils";

const Clients = () => {
  const { Title } = Typography;

  const history = useHistory();

  const [searchText, setSearchText] = useState("");

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleViewRecord = (record) => {
    // Navigate to another component and pass the record data
    history.push("/profile", { record });
  };

  const [clients, setClients] = useState([]);

  const getClients = async () => {
    try {
      if (isTokenExpired()) {
        // Log the user out or perform any required action
        // For example:
        localStorage.removeItem("access_token");
        localStorage.removeItem("expires");
        // Redirect to the logout page or show a message to the user
        history.push("/sign-in");
        // return;
      }
      //setLoading(true);
      const response = await axiosInstance.get(
        `https://easybills.pickmesms.com/users?filter[role][_eq]=606985a4-a39e-4ffc-b0ba-095e82d13316&fields=*,loans.*.*`
      );
      //setLoading(false);

      setClients(response.data.data);
    } catch (error) {
      //setLoading(false);
      //message.error(error.message);
    }
  };

  useEffect(() => {
    getClients();
  });

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "32%",
      render: (name, record) => (
        // Customize the rendering of the 'name' cell
        <Avatar.Group>
          <Avatar
            className="shape-avatar"
            shape="square"
            size={40}
            src={""}
          ></Avatar>
          <div className="avatar-info">
            <Title level={5}>
              {record.first_name + " " + record.last_name}
            </Title>
            <p>{record.email}</p>
          </div>
        </Avatar.Group>
      ),
    },
    {
      title: "EMPLOYER",
      dataIndex: "employer",
      key: "employer",
      render: (employer, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{employer}</Title>
          </div>
        </>
      ),
    },

    {
      title: "# OF LOANS",
      key: "loans",
      dataIndex: "loans",
      render: (loans, record) => (
        <>
          <Button
            type="primary"
            className="tag-primary"
            onClick={() => handleViewRecord(record)}
          >
            {loans.length}
          </Button>
        </>
      ),
    },
    {
      title: "PHONE",
      key: "phone",
      dataIndex: "phone",
      render: (phone, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{phone}</Title>
          </div>
        </>
      ),
    },
    {
      title: "VIEW",
      key: "view",
      render: (text, record) => (
        <Button type="link" onClick={() => handleViewRecord(record)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Clients"
              extra={
                <>
                  <Input.Search
                    placeholder="Search"
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchText}
                  />
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={clients}
                  className="ant-border-space"
                  pagination={{
                    pageSize: 5,
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Clients;
