import { Row, Col, Card, Table, Button, Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const Profileloans = ({ loanslist }) => {
  const { Title } = Typography;

  const history = useHistory();

  const handleViewRecord = (loan) => {
    history.push("/loandetails", { loan });
  };

  const columns = [
    {
      title: "LOAN AMOUNT",
      dataIndex: "loan_amount",
      key: "loan_amount",
      width: "15%",
      render: (loan_amount, record) => (
        <div className="avatar-info">
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckOutlined
              style={{ color: "green", fontSize: "20px", marginRight: "20px" }}
            />
            <div>
              <Title level={5}>
                K{parseFloat(loan_amount).toLocaleString()}
              </Title>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Date Approved",
      dataIndex: "date_approved",
      key: "date_approved",
      render: (date_approved, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{date_approved}</Title>
          </div>
        </>
      ),
    },
    {
      title: "REPAYABLE",
      dataIndex: "repayable",
      key: "repayable",
      render: (repayable, record) => (
        <>
          <div className="author-info">
            <Title level={5}>K{parseFloat(repayable).toLocaleString()}</Title>
          </div>
        </>
      ),
    },

    {
      title: "Installment",
      key: "istallment",
      dataIndex: "istallment",
      render: (istallment, record) => (
        <>
          <div className="author-info">
            <Title level={5}>K{parseFloat(istallment).toLocaleString()}</Title>
          </div>
        </>
      ),
    },
    {
      title: "Rate",
      key: "rate",
      dataIndex: "rate",
      render: (rate, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{rate}%</Title>
          </div>
        </>
      ),
    },
    {
      title: "Tenor",
      key: "tenor",
      dataIndex: "tenor",
      render: (tenor, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{tenor} months</Title>
          </div>
        </>
      ),
    },
    {
      title: "VIEW",
      key: "transactions",
      render: (transactions, record) => (
        <Button type="link" onClick={() => handleViewRecord(transactions)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Loans"
          >
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={loanslist}
                className="ant-border-space"
                pagination={{
                  pageSize: 5,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Profileloans;
