// utils.js
export const isTokenExpired = () => {
  const access_token = localStorage.getItem("access_token");
  const expires = localStorage.getItem("expires");
  if (!access_token || !expires) return true;

  const expirationTime = new Date(expires).getTime();
  const currentTime = new Date().getTime();

  return currentTime > expirationTime;
};
